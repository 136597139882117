<template>
    <div>
        <v-btn class="mb-4" @click="addStockMovement" color="primary" depressed rounded><v-icon left>mdi-plus</v-icon>Stockbeweging toevoegen</v-btn>
        <eod-expansion-panels v-if="value">
            <v-expansion-panel v-for="(stockMovement, stockMovementIndex) in value" :key="stockMovementIndex">
                <v-expansion-panel-header>
                    <div>
                    <strong>{{ stockMovement.name?stockMovement.name:'' }}</strong>
                    | {{ $moment(stockMovement.start).format('YYYY-MM-DD HH:mm') }}{{ stockMovement.end?' - ' + $moment(stockMovement.end).format('YYYY-MM-DD HH:mm'):'' }}
                </div>
                <div class="text-right px-4">
                    <strong>{{ stockMovement.quantity }}</strong>
                </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col><eod-date-time-picker label="Van*" :value="stockMovement.start" @input="val => updateStockMovement(stockMovementIndex, 'start', val)"></eod-date-time-picker></v-col>
                        <v-col><eod-date-time-picker label="Tot" :value="stockMovement.end" @input="val => updateStockMovement(stockMovementIndex, 'end', val)"></eod-date-time-picker></v-col>
                    </v-row>
                    <v-row>
                        <v-col><eod-text-field label="Omschrijving" :value="stockMovement.name" @input="val => updateStockMovement(stockMovementIndex, 'name', val)"></eod-text-field></v-col>
                        <v-col>
                            <eod-stockmovement-quantity :value="stockMovement.quantity" label="Aantal*" @input="val => updateStockMovement(stockMovementIndex, 'quantity', val)"></eod-stockmovement-quantity>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <eod-object-autocomplete label="Stocklocatie" endpoint="depots" :value="stockMovement.depot" return-object @input="val => updateStockMovement(stockMovementIndex, 'depot', val)"></eod-object-autocomplete>
                        </v-col>
                        <v-col>
                            <eod-object-autocomplete label="Geproduceerd door" endpoint="products" :value="stockMovement.product" return-object @input="val => updateStockMovement(stockMovementIndex, 'product', val)"></eod-object-autocomplete>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </eod-expansion-panels>
    </div>
</template>
<script>
import eodDateTimePicker from './eod-date-time-picker.vue'
import eodObjectAutocomplete from './eod-object-autocomplete.vue'
import eodStockmovementQuantity from './eod-stockmovement-quantity.vue';
export default{
    name: 'eod-article-stock-movements',
    components:{
        eodDateTimePicker,
        eodObjectAutocomplete,
        eodStockmovementQuantity
    },
    props:{
        value: Array
    },
    methods:{
        addStockMovement(){
            const val = this.value;
            if(!this.value){
                val = [];
            }
            
            val.push({
                name: 'Stock',
                start: this.$moment().toISOString(),
                end: this.$moment().toISOString(),
                quantity: 1,
            });

            this.$emit('input', val);
        },
        updateStockMovement(index, propertyName, value){
            const val = this.value;
            val[index][propertyName] = value;
            this.$emit('input', val);
        }
    }
}
</script>