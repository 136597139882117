<template>
    <v-form id="workflowForm" ref="form" v-if="task">
        <v-stepper v-if="activeTemplateIndex != null" v-model="stepperStep" @change="stepChanged" class="mb-4 d-none d-md-block" flat outlined>
                    <v-stepper-header>
                        <template v-for="(page, index) in stepperPages">
                            <v-stepper-step :step="index + 1" :key="index" :editable="index < currentStepperStep">
                                {{ page.name }}
                            </v-stepper-step>
                            <v-divider v-if="index < stepperPages.length - 1" :key="'divider_' + index"></v-divider>
                        </template>
                    </v-stepper-header>
                </v-stepper>
        <div class="mt-0">
                <eod-card outlined title="Taak" class="mb-4">
                            <v-card-text>
                <eod-object-autocomplete v-if="!task.id" endpoint="taskTypes"
                :fields="['id name templates{id name content type object}']" :value="task.taskTypeId" return-object
                label="Selecteer workflow*" @input="taskTypesChanged"></eod-object-autocomplete>
            <v-alert type="error" text v-if="errors.taskTypeId" dense class="mt-1">{{ errors.taskTypeId }}</v-alert>

            <template v-if="activeTemplateIndex != null">
                <template v-if="cell != null">
                <eod-text-field v-if="!task.id" class="mt-4 mb-4"color="primary" v-model="task.name"
                    label="Naam van de workflow instantie*" :readonly="task.statusCode == 'done'" @keyup="taskNameChanged = true;"></eod-text-field>
                <v-alert type="error" text v-if="errors.name" dense class="mt-1">{{ errors.name }}</v-alert>
                <eod-expansion-panels v-model="topExpansionPanels" multiple>
                        <v-expansion-panel :key="0" :value="0" v-if="!mobile && getFormCell() && workflowJob && getFormCell().settings.plan"
                            :style="(cell.typeId == 'fixed_form' && cell.settings.type != 'plan') ? 'background-color:#F8F8F8;' : ''">
                            <v-expansion-panel-header>
                                <div><v-badge
                                        v-if="(task.dueRequestDateFormatted || (workflowJob && (workflowJob.dueAfterDateFormatted || workflowJob.dueBeforeDateFormatted || workflowJob.responsibleUsers)))"
                                        color="primary" inline dot><span>Planning</span></v-badge><span
                                        v-else>Planning</span></div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template v-if="cell.typeId == 'form'">
                                    <div class="d-flex align-items-center">
                                        <div class="text-subtitle-2" style="width:200px;">Gewenste planningsdatum</div>
                                        <v-menu offset-y max-width="400" :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-on="on" v-bind="attrs" :disabled="isStepReadonly"><span
                                                        v-if="task.dueRequestDateFormatted">{{
                                                            task.dueRequestDateFormatted
                                                        }}</span><span v-else>Geen</span></v-chip>
                                            </template>
                                            <vc-date-picker :popover="{ visibility: 'focus' }"
                                                :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }, { key: 'requested', highlight: { color: 'orange', fillMode: 'solid', class: 'success' }, dates: task.dueRequestDateFormatted }]"
                                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                                                v-model="task.dueRequestDateFormatted" is-expanded></vc-date-picker>
                                        </v-menu>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="text-subtitle-2" style="width:200px;">Geschatte tijdsduur (min)</div>
                                        <div>
                                    <eod-text-field class="mt-2" :disabled="(workflowJob.dueAfterDate?true:false) || isStepReadonly" :info="workflowJob.dueAfterDate?'Enkel aan te passen wanneer er nog geen \'van\'/\'tot\' is ingevuld':null" prepend-inner-icon="mdi-clock-outline" type="number" step="0.1" @input="val => { workflowJob.estimatedDuration = val * 60000 }" :value="workflowJob.estimatedDuration / 60000"></eod-text-field>
                                </div>
                                    </div>
                                    <v-checkbox class="mt-0" hide-details
                                        v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && task.dueRequestDate"
                                        label="Taak moet worden ingepland op gewenste planningsdatum"
                                        v-model="task.mustBePlannedOnRequestDate" :disabled="isStepReadonly"></v-checkbox>

                                    <template v-if="workflowJob && workflowJob.skills && workflowJob.skills[0]">
                                        <div class="text-subtitle-2 mr-2 mt-2">Vaardigheden</div>
                                        <eod-tags setting-key="organization.skills.listId" v-model="workflowJob.skills"
                                            small addable deletable></eod-tags>
                                    </template>
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <div class="text-subtitle-2 mb-2">Datum</div>
                                            <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="15"
                                                :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }, { key: 'requested', highlight: { color: 'orange', fillMode: 'solid', class: 'success' }, dates: task.dueRequestDateFormatted }]"
                                                :value="workflowJob.dueAfterDateFormatted" @input="dueAfterDateChanged"
                                                mode="dateTime" is24hr
                                                :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                                                <template v-slot="{ inputValue, inputEvents, updateValue }">
                                                    <v-label>Van</v-label>
                                                    <eod-text-field class="mb-4" hide-details
                                                        prepend-icon="mdi-calendar" :value="inputValue"
                                                        :disabled="isStepReadonly"
                                                        @input=" updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                                        @change=" updateValue($event, { formatInput: true, hidePopover: false, })"
                                                        v-on="inputEvents" clearable></eod-text-field>
                                                </template>
                                            </vc-date-picker>
                                            <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="15"
                                                :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                                                :value="workflowJob.dueBeforeDateFormatted"
                                                @input="dueBeforeDateChanged" mode="dateTime" is24hr
                                                :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                                                <template v-slot="{ inputValue, inputEvents, updateValue }">
                                                    <v-label>Tot</v-label>
                                                    <eod-text-field hide-details prepend-icon="mdi-calendar"
                                                        :value="inputValue"
                                                        :disabled="isStepReadonly"
                                                        @input=" updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                                        @change=" updateValue($event, { formatInput: true, hidePopover: false, })"
                                                        v-on="inputEvents" clearable></eod-text-field>
                                                </template>
                                            </vc-date-picker>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="text-subtitle-2 mb-2">Gebruikers</div>
                                            <eod-contacts ref="contacts" :importShow="false" :exportShow="false"
                                                :roles="settings.roles.collaborators"
                                                :readonly="isStepReadonly"
                                                v-model="workflowJob.responsibleUsers"></eod-contacts>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else-if="cell.typeId == 'fixed_form' && cell.settings.type == 'plan'">
                                    <div class="d-flex align-items-center">
                                        <div class="text-subtitle-2" style="width:200px;">Gewenste planningsdatum</div>
                                        <v-menu offset-y :close-on-content-click="false" max-width="400">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-on="on" v-bind="attrs" :disabled="isStepReadonly"><span
                                                        v-if="task.dueRequestDateFormatted">{{
                                                            task.dueRequestDateFormatted
                                                        }}</span><span v-else>Geen</span></v-chip>
                                            </template>
                                            <vc-date-picker
                                                :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }, { key: 'requested', highlight: { color: 'orange', fillMode: 'solid', class: 'success' }, dates: task.dueRequestDateFormatted }]"
                                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                                                v-model="task.dueRequestDateFormatted" is-expanded></vc-date-picker>
                                        </v-menu>
                                    </div>
                                    <v-checkbox class="mt-0" hide-details
                                        v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && task.dueRequestDate"
                                        label="Taak moet worden ingepland op gewenste planningsdatum"
                                        v-model="task.mustBePlannedOnRequestDate"></v-checkbox>

                                    <template v-if="workflowJob && workflowJob.skills && workflowJob.skills[0]">
                                        <div class="text-subtitle-2 mr-2 mt-2">Vaardigheden</div>
                                        <eod-tags setting-key="organization.skills.listId" v-model="workflowJob.skills"
                                            small addable deletable></eod-tags>
                                    </template>
                                    <v-alert v-if="task.details" type="warning" dense class="mt-4">De taak kon niet
                                        worden ingepland met
                                        bovenstaande gegevens.
                                        Gelieve deze
                                        aan te passen en opnieuw te verzenden.<br>Voor meer details, zie onderstaande
                                        foutmeldingen.</v-alert>
                                    <v-data-table v-if="task.details" :items="task.details.days"
                                        :headers="[{ text: 'Datum', value: 'date' }, { text: 'Foutmelding', value: 'errors[0].description' }]"></v-data-table>
                                </template>
                                <template v-else>
                                    <v-alert dense type="info" text>De planning kan enkel worden aangepast tijdens het
                                        inplannen of uitvoeren.</v-alert>
                                    <div class="d-flex align-items-center">
                                        <div class="text-subtitle-2 mr-2">Gewenste planningsdatum</div>
                                        <v-chip outlined disabled><span v-if="task.dueRequestDateFormatted">{{
                                            task.dueRequestDateFormatted
                                        }}</span><span v-else>Geen</span></v-chip>
                                    </div>
                                    <v-checkbox class="mt-0" hide-details
                                        v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && task.dueRequestDate" disabled
                                        label="Taak moet worden ingepland op gewenste planningsdatum"
                                        v-model="task.mustBePlannedOnRequestDate"></v-checkbox>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                       
                        <v-expansion-panel :key="1" :value="1">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="task.description && task.description != ''" color="primary" inline
                                        dot><span>Omschrijving</span></v-badge><span v-else>Omschrijving</span></div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-rte :disabled="(mobile && $eod.getOrganizationSetting('mobile.tasks.description.readonly').value == 1) || isStepReadonly" v-model="task.description" @input="value => updateTaskProperty('description', value)"></eod-rte>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="workflowJob && workflowJob.stepType == 'PLAN'" :key="2" :value="2">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="totalProductsCount" color="primary" :content="totalProductsCount"
                                        inline><span>Benodigdheden</span></v-badge><span v-else>Benodigdheden</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-producttypes-products-selector :readonly="isStepReadonly" item-required v-model="workflowJob"></eod-producttypes-products-selector>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!mobile || (mobile && task.storageObjects && task.storageObjects.length > 0)" :key="3" :value="3">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="task.storageObjects && task.storageObjects.length > 0" color="primary"
                                        :content="task.storageObjects.length" inline><span>Media</span></v-badge><span
                                        v-else>Media</span></div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="pb-4">
                                    <eod-storage-library v-if="task.id || task.storageObjects" :uploadLibrarySearchFilters="storageSearchFilters()" :uploadBtn="!mobile && !isStepReadonly" :readonly="mobile || isStepReadonly" @updated="storageObjectUpdated" @added="storageObjectAdded" hide-edit hide-tags :storageObjects="task.storageObjects?task.storageObjects.map((item) => {return item.id}):[]" :tags="{projectId: task.project.id, taskId: task.id, companyId: task.company.id, taskTypeId: task.taskType.id, serviceId: task.service.id, userId: $eod.getUser().id}" :filterable="false" :searchable="false"></eod-storage-library>
                                    <v-alert v-else type="warning" color="orange" border="left" dense>
                                        <div class="d-flex align-center">
                                            Gelieve op te slaan om media toe te voegen.<v-spacer></v-spacer>
                                            <v-tooltip top :disabled="(task.projectId && task.serviceId)?true:false">
                                                <template v-slot:activator="{attrs, on}">
                                                    <div v-on="on" v-bind="attrs" >
                                                    <v-btn @click="save(null, true)" :disabled="(task.projectId && task.serviceId)?false:true"  small color="orange lighten-2" rounded depressed><v-icon>mdi-content-save</v-icon> Opslaan</v-btn>
                                                    </div>
                                                </template>
                                                U dient een project en dienst te selecteren vooraleer u kunt bewaren
                                            </v-tooltip>
                                            
                                        </div>
                                    </v-alert>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="task.id && task.dueAfterDate" :key="4" :value="4">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="totalEventsCount" color="primary" :content="totalEventsCount"
                                        inline><span>Gebeurtenissen</span></v-badge><span v-else>Gebeurtenissen</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-events v-model="workflowEvents" :filters="getEventsFilters()" :readonly="isStepReadonly"
                                    :defaultEvent="{ projectId: task.project.id, companyId: task.company.id, serviceId: task.service.id, time: task.dueAfterDate }"></eod-events>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </eod-expansion-panels>
                    </template>
                    </template>
            </v-card-text>
            </eod-card>
            <template v-if="activeTemplateIndex != null">
                <template v-if="cell != null">
                    <div v-if="cell.typeId == 'fixed_form' && cell.settings.type == 'info'" :key="cell.id">
                        <eod-card outlined title="Project">
                            <v-card-text>
                        <eod-objects-treeview-input
                                                        :disabled="task.statusCode == 'done' || isStepReadonly"
                                                        label="Project*"
                                                        :selectable="['PROJECT']"
                                                        :classes="['COMPANY','PROJECT']"
                                                        :open="[task.companyId]"
                                                        :value="task.projectId"
                                                        @input="projectsChanged">
                                                    <template v-slot:append-item>
                                <v-divider ></v-divider>
                                <v-list-item dense id="add_service" @click.stop="isCreateProjectDialogVisible = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Nieuw project toevoegen</v-list-item-title>
                                        <v-list-item-subtitle>Maak een project aan en voeg deze toe aan de taak</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                                                    </eod-objects-treeview-input>
                                                    <eod-dialog v-if="isCreateProjectDialogVisible" v-model="isCreateProjectDialogVisible" :loading="isCreateProjectDialogLoading" title="Project toevoegen" icon="mdi-plus" iconColor="primary" width="1200" @okAction="createProject" okText="Toevoegen">
                            <eod-project-detail class="mt-4" outlined compact v-model="createProjectItem"></eod-project-detail>
                        </eod-dialog>
                        <v-alert type="warning" text dense class="mt-1" v-if="availabilityError">
                            Project is niet beschikbaar voor gekozen tijdstip!
                        </v-alert>
                        <v-alert type="error" text dense class="mt-1" v-if="errors.projectLocation">{{
                            errors.projectLocation
                        }}</v-alert>
                        <v-alert type="error" text dense class="mt-1" v-if="errors.projectId">{{
                            errors.projectId
                        }}</v-alert>

                        <template v-if="(!hideService && task.serviceId) || (task.projectId && !task.serviceId)">
                            <eod-autocomplete v-if="task.projectId" class="mt-4" outlined hide-details :items="services"
                                label="Dienst*" v-model="task.serviceId" item-text="name" item-value="id" required
                                :readonly="task.statusCode == 'done' || isStepReadonly" @input="serviceChanged"></eod-autocomplete>
                            <v-alert type="error" text v-if="task.projectId && errors.serviceId" dense class="mt-1">{{
                                errors.serviceId
                            }}</v-alert>
                        </template>
                    </v-card-text>
                    </eod-card>
                        <eod-card class="mt-4" v-if="forms_per_page.length > 0" title="Formulieren" subtitle="Overzicht van de formulieren in deze workflow" outlined>
                            <v-card-text>
                                <eod-expansion-panels accordion v-model="forms_per_page_open" flat>
                                    <v-expansion-panel
                                        v-for="form_cell in forms_per_page"
                                        :key="form_cell.id" style="border:1px solid #dddddd">
                                        <v-expansion-panel-header>
                                            {{ form_cell.name }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <eod-object-autocomplete class="mb-4" endpoint="jobTypes" clearable
                                                :suggestions="forms_jobtype_suggestions"
                                                :disabled="form_cell.settings.type == 'fixed' || isStepReadonly"
                                                @change="value => setJobTypeTemplate(value, form_cell)"
                                                :value="form_cell.settings.jobTypeId" :label="'Formulier*'"
                                                return-object
                                                :fields="['id', 'name', 'isConnectable', 'estimatedDuration', 'connectableConfig{content}', 'articles{id name quantity articleType{id name}}', 'articleTypes{id name quantity}', 'templates{id type content users{id} jobTypes{id}}']"></eod-object-autocomplete>
                                            <v-alert type="error" text v-if="errors.forms && errors.forms[form_cell.id]"
                                                dense class="mt-1">{{ errors.forms[form_cell.id] }}</v-alert>
                                            <template
                                                v-if="jobTypeTemplates[form_cell.settings.jobTypeId] && jobTypeTemplates[form_cell.settings.jobTypeId].template">
                                                <template v-if="taskHasPlanStep()">
                                                <v-checkbox v-if="!($eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true)" hide-details
                                                    label="Onmiddellijk uitvoeren" v-model="task.templates[activeTemplateIndex].content.skipPlan" :disabled="isStepReadonly"
                                                    @change=" $forceUpdate()"></v-checkbox>
                                                <v-menu offset-y v-if="!task.templates[activeTemplateIndex].content.skipPlan"
                                                    :close-on-content-click="false" v-model="dueRequestDateMenu" max-width="400" :disabled="isStepReadonly">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <eod-text-field prepend-inner-icon="mdi-calendar-outline" :readonly="isStepReadonly" class="mt-4" v-on="on" v-bind="attrs" :label="'Gewenste planningsdatum' + ($eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true ? '*' : '')" @input="val => { if (val) { dueRequestDateMenu = false } }" :value="task.dueRequestDateFormatted"></eod-text-field>
                                                    </template>
                                                    <vc-date-picker
                                                        :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }, { key: 'requested', highlight: { color: 'orange', fillMode: 'solid', class: 'success' }, dates: task.dueRequestDateFormatted }]"
                                                        :popover="{ visibility: 'focus' }"
                                                        :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                                                        v-model="task.dueRequestDateFormatted"
                                                        @input="val => { if (val) { dueRequestDateMenu = false } }" is-expanded></vc-date-picker>
                                                </v-menu>
                                                <v-alert type="error" text v-if="errors.dueRequestDate" dense class="mt-1">{{
                                                    errors.dueRequestDate
                                                }}</v-alert>
                                                <v-checkbox class="mt-0" hide-details
                                                    v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && task.dueRequestDate && task.serviceId && !task.templates[activeTemplateIndex].content.skipPlan"
                                                    label="Taak moet worden ingepland op gewenste planningsdatum"
                                                    :disabled="isStepReadonly"
                                                    v-model="task.mustBePlannedOnRequestDate"></v-checkbox>
                                                </template>
                                                <eod-text-field :disabled="isStepReadonly" prepend-inner-icon="mdi-clock-outline" class="mt-4" label="Geschatte tijdsduur (min)" type="number" step="0.1" @input="val => { task.estimatedDuration = val * 60000 }" :value="task.estimatedDuration / 60000"></eod-text-field>
                                                <template
                                                    v-if="jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variants && jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variants.length > 0">
                                                    <eod-autocomplete class="mt-4" label="Variant" hide-details :disabled="isStepReadonly"
                                                        :items="jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variants"
                                                        v-model="task.templates[activeTemplateIndex].content.pages[form_cell.pageIndex].cells[form_cell.index].settings.defaultVariant"></eod-autocomplete>
                                                </template>
                                                <template
                                                    v-if="jobTypeTemplates[form_cell.settings.jobTypeId].isConnectable">
                                                    <v-autocomplete class="mb-4" label="Dataconnector*" dense outlined
                                                        hide-details
                                                        :items="jobTypeTemplates[form_cell.settings.jobTypeId].dataConnectorsResolved"
                                                        :value="getDataConnectorResolved(task.templates[activeTemplateIndex].content.pages[form_cell.pageIndex].cells[form_cell.index].settings.jobTypeConfigTemplateId, form_cell)"
                                                        @change="val => setDataConnectorTemplate(val, form_cell)"
                                                        return-object>
                                                    <template v-slot:append-item>
                                                        <v-list-item @click="dataconnectorJobtypeDialogShowAction">
                                                        <v-list-item-avatar>
                                                            <v-avatar color="primary" size="30">
                                                                <v-icon dark>mdi-plus</v-icon>
                                                            </v-avatar>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Connectie toevoegen</v-list-item-title>
                                                            <v-list-item-subtitle>Voeg een dataconnector toe aan {{jobTypeTemplates[form_cell.settings.jobTypeId].name}}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    </template>
                                                    </v-autocomplete>
                                                </template>
                                                <eod-dialog width="800" v-if="dataconnectorJobtypeDialogShow" v-model="dataconnectorJobtypeDialogShow" title="Dataconnector toevoegen" icon="mdi-connection" iconColor="primary">
                                                    <eod-user-selector class="mt-4" @change="dataconnectorJobtypeDialogUserChanged" dense label="Dataconnector" :roleNames="['connector']" :value="dataconnectorJobtypeDialogUser"></eod-user-selector>
                                                    <v-card v-if="dataconnectorJobtypeDialogUser && dataconnectorJobtypeDialogOwner" outlined class="mt-4">
                                                        <v-card-text>
                                                            <eod-user-job-types-config ref="userJobTypesConfig" v-model="dataconnectorJobtypeDialogOwner.templates" :jobTypeId="form_cell.settings.jobTypeId" :ownerId="dataconnectorJobtypeDialogUser"></eod-user-job-types-config>
                                                        </v-card-text>
                                                    </v-card>
                                                    <template v-slot:actions>
                                                        <v-btn text @click="dataconnectorJobtypeDialogShow = false">Annuleren</v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="primary" depressed @click="saveDataconnectorConfig(form_cell)" :loading="dataconnectorJobtypeDialogLoading" rounded><v-icon left>mdi-content-save-outline</v-icon>Opslaan</v-btn>
                                                    </template>
                                                </eod-dialog>
                                                <template
                                                    v-if="jobTypeTemplates[form_cell.settings.jobTypeId].envVariables && jobTypeTemplates[form_cell.settings.jobTypeId].envVariables.length > 0">
                                                    <div class="text-subtitle-2 mt-4 mb-4">Flow variabelen</div>
                                                    <v-text-field class="mb-4"
                                                    dense outlined
                                                        v-for="variable in jobTypeTemplates[form_cell.settings.jobTypeId].envVariables"
                                                        :label="variable.name"
                                                        :key="variable.name"
                                                        :disabled="isStepReadonly"
                                                        v-model="task.templates[activeTemplateIndex].content.pages[form_cell.pageIndex].cells[form_cell.index].settings.defaultEnvVariables[variable.name].value"></v-text-field>
                                                </template>
                                                <template
                                                    v-if="jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variables && jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variables.length > 0">
                                                    <div class="text-subtitle-2 mt-4 mb-2">Toestellen</div>
                                                    <div class="mb-4" v-for="variable in jobTypeTemplates[form_cell.settings.jobTypeId].template.content.variables" :key="variable.id">
                                                    <eod-objects-treeview-input
                                                        :disabled="getJobTypeTemplateVariable(form_cell.settings.jobTypeId, variable.value).product != null || isStepReadonly"
                                                        :label="variable.text"
                                                        
                                                        :selectable="['DEVICE']"
                                                        :open="[task.projectId]"
                                                        :search="getProjectSearchFilter()"
                                                        v-model="task.templates[activeTemplateIndex].content.pages[form_cell.pageIndex].cells[form_cell.index].settings.defaultVariables[variable.value]"
                                                        return-object></eod-objects-treeview-input>
                                                    </div>
                                                </template>
                                                <template v-if="form_cell.planPageIndex">
                                                    <div class="text-subtitle-2 mt-4 mb-2">Benodigdheden</div>
                                                    <eod-producttypes-products-selector v-model="task.templates[activeTemplateIndex].content.pages[form_cell.planPageIndex].cells[form_cell.planCellIndex].settings"></eod-producttypes-products-selector>
                                                </template>
                                            </template>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </eod-expansion-panels>
                            </v-card-text>
                        </eod-card>
                    </div>
                    <div v-if="cell.typeId == 'fixed_form' && cell.settings.type == 'report'" :key="cell.id">
                        <v-tabs @change="updateReportTab" v-model="reportAutomationTabs">
                            <v-tab>Mail</v-tab>
                            <v-tab>Rapport</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="reportAutomationTabs">
                            <v-tab-item :transition="false">
                        <eod-workflow-mail-config ref="mailConfigComponent" :contacts="task.project.users||[]" :readonly="isStepReadonly"
                            v-model="task.templates[activeTemplateIndex].content.pages[step].cells[0].settings.mail" @addContact="addUserToProject"></eod-workflow-mail-config>
                            </v-tab-item>
                            <v-tab-item :transition="false">
                                <v-toolbar v-if="reportPreviewResponse" dense outlined flat class="mt-4">
                                    <eod-autocomplete label="Taal" :items="$eod.getLanguages()" v-model="reportPreviewLanguage" @change="changeReportLanguage"></eod-autocomplete>
                                    <v-spacer></v-spacer>
                                    <v-btn :to="{name:'response', params:{id:reportPreviewResponse.id}}" target="_blank" depressed small rounded>Rapport aanpassen</v-btn>
                                </v-toolbar>
                                <div class="position-relative grey" style="height:800px;">
                                    <form ref="preview_form" target="preview_frame" method="post"
                                        :action="$eod.api_url + '/report/preview#toolbar=0'">
                                        <input v-if="reportPreviewLanguage" type="hidden" name="lang" :value="reportPreviewLanguage" />
                                        <input type="hidden" name="template" :value="JSON.stringify(reportPreviewTemplate)" />
                                        <input type="hidden" name="bearerToken" :value="$keycloak.token" />
                                    </form>
                                    <iframe v-show="!reportPreviewLoading" @load="reportPreviewLoading = false" ref="preview_frame" name="preview_frame" frameborder="0" style="border:none;width:100%;height:100%;"></iframe>
                                    <div v-if="reportPreviewLoading" class="d-flex justify-content-center align-items-center py-4">
                                        <eod-loader :value="reportPreviewLoading" text="Bezig met genereren..."></eod-loader>
                                    </div>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                    <div v-if="cell.typeId == 'fixed_form' && cell.settings.type == 'plan' && workflowJob"
                        :key="cell.id">
                        <v-container fluid style="min-height:450px;">
                            <v-alert type="warning" dense v-if="workflowJob.route">Onderstaande gegevens zijn een voorstel.</v-alert>
                            <eod-text-field :disabled="(workflowJob.dueAfterDate?true:false) || isStepReadonly" :info="workflowJob.dueAfterDate?'Enkel aan te passen wanneer er nog geen \'van\'/\'tot\' is ingevuld':null" prepend-inner-icon="mdi-clock-outline" class="mb-4" label="Geschatte tijdsduur (min)" type="number" step="0.1" @input="val => { workflowJob.estimatedDuration = val * 60000 }" :value="workflowJob.estimatedDuration / 60000"></eod-text-field>
                            <template
                            v-if="jobTypeTemplates[getFormCell().settings.jobTypeId] && jobTypeTemplates[getFormCell().settings.jobTypeId].template.content.variants && jobTypeTemplates[getFormCell().settings.jobTypeId].template.content.variants.length > 0">
                                                    <eod-autocomplete class="mb-4" label="Variant*" hide-details :disabled="isStepReadonly"
                                                    :rules="[val => $validator.required(val, 'Variant')]"
                                                        :items="jobTypeTemplates[getFormCell().settings.jobTypeId].template.content.variants"
                                                        v-model="task.templates[activeTemplateIndex].content.pages[getFormCell().pageIndex].cells[getFormCell().index].settings.defaultVariant"></eod-autocomplete>
                                                </template>
                            <div class="text-subtitle-2">Datum</div>
                            <v-container fluid class="mb-4">
                                <v-row>
                                    <v-col>
                                        <vc-date-picker :popover="{ visibility: 'focus' }"
                                            :minute-increment="workflowJob.route ? 1 : 15"
                                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }, { key: 'requested', highlight: { color: 'orange', fillMode: 'solid', class: 'success' }, dates: workflowJob.dueRequestDate }]"
                                            :value="workflowJob.dueAfterDateFormatted" @input="dueAfterDateChanged"
                                            mode="dateTime" is24hr
                                            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                                <v-label>Van</v-label>
                                                <eod-text-field hide-details prepend-icon="mdi-calendar"
                                                    :disabled="($eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true) || isStepReadonly" :value="inputValue"
                                                    @input=" updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                                    @change=" updateValue($event, { formatInput: true, hidePopover: false, })"
                                                    v-on="inputEvents" clearable></eod-text-field>
                                            </template>
                                        </vc-date-picker>
                                    </v-col>
                                    <v-col>
                                        <vc-date-picker :popover="{ visibility: 'focus' }"
                                            :minute-increment="workflowJob.route ? 1 : 15"
                                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                                            :value="workflowJob.dueBeforeDateFormatted" @input="dueBeforeDateChanged"
                                            mode="dateTime" is24hr
                                            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                                <v-label>Tot</v-label>
                                                <eod-text-field hide-details prepend-icon="mdi-calendar"
                                                    :disabled="($eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true) || isStepReadonly" :value="inputValue"
                                                    @input=" updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                                    @change=" updateValue($event, { formatInput: true, hidePopover: false, })"
                                                    v-on="inputEvents" clearable></eod-text-field>
                                            </template>
                                        </vc-date-picker>
                                    </v-col>
                                </v-row>
                                <v-alert type="warning" text dense class="mt-1" v-if="availabilityError">
                            Project is niet beschikbaar voor gekozen tijdstip!
                        </v-alert>
                            </v-container>

                            <v-row>
                                <v-col cols=12 md=6>
                                    <div class="text-h7 mb-4">Gebruikers</div>
                                    <eod-contacts ref="contacts" :importShow="false" :exportShow="false"
                                        :roles="settings.roles.collaborators"
                                        :readonly="isStepReadonly"
                                        v-model="workflowJob.responsibleUsers"></eod-contacts>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <div v-if="cell.typeId == 'form' || (cell.typeId == 'fixed_form' && cell.settings.type == 'verify')"
                        :key="cell.id" class="mt-2" outlined>
                        <div class="text-subtitle-1 mb-2" v-if="!hideTitle">{{ cell.name }}</div>
                        <template v-if="workflowJob && workflowJob.jobType">
                            <v-row v-if="workflowJob && workflowJob.stepType == 'VERIFY' && workflowjob_response">
                                <v-col>
                                    <eod-comments :readonly="isStepReadonly" :tags="{
                                        projectId: workflowJob.project?workflowJob.project.id:null,
                                        serviceId: workflowJob.service?workflowJob.service.id:null,
                                        companyId: workflowJob.company?workflowJob.company.id:null,
                                        userId: $eod.getUser().id,
                                    }" v-model="workflowjob_response.comments" collapsable show-start-workflow
                                        @createWorkflow="showCreateWorkflow"></eod-comments>
                                </v-col>
                                <v-col>
                                    <eod-timetrackings :readonly="isStepReadonly" v-model="workflowjob_response.timetrackings" collapsable
                                        @add="addTracking" @edit="editTracking"></eod-timetrackings>
                                    <v-dialog v-model="addTrackingDialog" persistent max-width="550">
                                        <v-card>
                                            <v-card-title class="mb-4">
                                                <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                                    <v-icon dark>
                                                        mdi-timer-outline
                                                    </v-icon>
                                                </v-avatar><span class="headline">Timetracking</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <eod-add-timetracking v-model="newTracking"></eod-add-timetracking>
                                            </v-card-text>
                                            <v-card-actions class="grey lighten-3 py-3 px-3">
                                                <v-spacer></v-spacer>
                                                <v-btn text @click=" addTrackingDialog = false" rounded>Annuleren</v-btn>
                                                <v-btn color="secondary" depressed rounded @click="saveTracking">
                                                    <v-icon left>mdi-content-save</v-icon> Opslaan
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <eod-task-form ref="taskForm" @onCreateTask="showCreateWorkflow" @input="responseChanged"
                                @loaded="taskFormLoaded"
                                @deleteCell="deleteTemplateCell"
                                v-if="workflowJob && getFormTemplate(workflowJob) && workflowjob_response"
                                :template="getFormTemplate(workflowJob)"
                                @updateTemplate="value => setFormTemplate(value)"
                                :mobile="mobile"
                                :readonly="isStepReadonly"
                                v-model="workflowjob_response" :sendButton="false"></eod-task-form>
                        </template>
                        <eod-object-autocomplete v-else-if="workflowJob" label="Workflow" endpoint="jobTypes" :disabled="isStepReadonly"
                            return-object @change="value => setJobType(value)" :value="cell.settings.jobTypeId"
                            :fields="['id', 'name', 'isConnectable', 'connectableConfig{content}', 'articles{id name quantity articleType{id name}}', 'articleTypes{id name quantity}', 'templates{id type content}']"></eod-object-autocomplete>
                    </div>
                </template>
                <template v-else>
                    <v-alert dense type="info" text>Deze workflow is afgewerkt. Er kunnen geen wijzigingen meer worden
                        doorgevoerd.</v-alert>
                    <eod-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>Planning</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-text-field disabled prepend-inner-icon="mdi-clock-outline" class="mb-4" label="Geschatte tijdsduur (min)" type="number" step="0.1" :value="task.estimatedDuration / 60000"></eod-text-field>
                                <div class="d-flex align-items-center mb-2">
                                    <div class="text-subtitle-2 mr-2">Gewenste planningsdatum</div>
                                    <v-chip outlined><span v-if="task.dueRequestDateFormatted">{{
                                        task.dueRequestDateFormatted
                                    }}</span><span v-else>Geen</span></v-chip>
                                </div>
                                <v-checkbox class="mt-0" disabled hide-details
                                    v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && task.dueRequestDate"
                                    label="Taak moet worden ingepland op gewenste planningsdatum"
                                    v-model="task.mustBePlannedOnRequestDate"></v-checkbox>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div><v-badge v-if="task.description && task.description != ''" color="primary" inline
                                        dot><span>Omschrijving</span></v-badge><span v-else>Omschrijving</span></div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-rte :value="task.description" disabled></eod-rte>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="task.id">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="task.storageObjects && task.storageObjects.length > 0" color="primary"
                                        :content="task.storageObjects.length" inline><span>Media</span></v-badge><span
                                        v-else>Media</span></div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col
                                        v-for="(object, index) in task.storageObjects"
                                        :key="object.id" cols="12" sm="6" md="4" lg="2">
                                        <v-card>
                                            <v-img contain :src="$eod.getImageUrl(object.key)"></v-img>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="task.id && task.dueAfterDate">
                            <v-expansion-panel-header>
                                <div><v-badge v-if="totalEventsCount" color="primary" :content="totalEventsCount"
                                        inline><span>Gebeurtenissen</span></v-badge><span v-else>Gebeurtenissen</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <eod-events v-model="workflowEvents" readonly :filters="getEventsFilters()"
                                    :defaultEvent="{ projectId: task.project.id, companyId: task.company.id, serviceId: task.service.id }"></eod-events>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-for="form_cell in forms_per_page"
                            :key="form_cell.id">
                            <v-expansion-panel-header>
                                {{ form_cell.name }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="text-subtitle-2 mb-2">Datum</div>
                                        <div class="d-flex align-items-center mb-2">
                                            <div class="text-subtitle-2 mr-2">Van</div>
                                            <v-chip outlined><span v-if="form_cell.job.dueAfterDateFormatted">{{
                                                form_cell.job.dueAfterDateFormatted
                                            }}</span><span v-else>-</span></v-chip>
                                        </div>
                                        <div class="d-flex align-items-center mb-2">
                                            <div class="text-subtitle-2 mr-2">Tot</div>
                                            <v-chip outlined><span v-if="form_cell.job.dueBeforeDateFormatted">{{
                                                form_cell.job.dueBeforeDateFormatted
                                            }}</span><span v-else>-</span></v-chip>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="text-subtitle-2 mb-2">Gebruikers</div>
                                        <eod-contacts ref="contacts" readonly outlined :value="form_cell.job.responsibleUsers"
                                            :roles="settings.roles.collaborators"></eod-contacts>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </eod-expansion-panels>
                </template>
            </template>

        </div>
    </v-form>
</template>
<script>
import {Task, Template, Measurement, User, DataConnector, Product, Project} from './../../index.js'
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodItemTreeviewSelector from './eod-item-treeview-selector.vue';
import eodContacts from './eod-contacts.vue';
import eodProducts from './eod-products.vue';
import eodTags from './eod-tags.vue';
import eodProductTypes from './eod-productTypes.vue';
import eodComments from './eod-comments.vue';
import eodTimetrackings from './eod-timetrackings.vue';
import eodAddTimetracking from './eod-add-timetracking.vue';
import eodEvents from './eod-events.vue';
import eodWorkflowMailConfig from './eod-workflow-mail-config.vue';
import eodUserSelector from './eod-user-selector.vue';
import eodDialog from './eod-dialog.vue';
import eodUserJobTypesConfig from './eod-job-types-config.vue';
import eodStorageLibrary from './eod-storage-library.vue';
import eodLoader from './eod-loader.vue';
import eodRte from './eod-rte.vue';
import eodProjectDetail from './eod-project-detail.vue';
import eodProducttypesProductsSelector from './eod-producttypes-products-selector.vue'

const _ = require('lodash');

export default {
    name: 'eod-workflow',
    components: {
        eodObjectAutocomplete,
        eodItemTreeviewSelector,
        eodContacts,
        eodProducts,
        eodProductTypes,
        eodComments,
        eodTimetrackings,
        eodAddTimetracking,
        eodRte,
        eodTags,
        eodEvents,
        eodWorkflowMailConfig,
        eodUserSelector,
        eodDialog,
        eodUserJobTypesConfig,
        eodStorageLibrary,
        eodLoader,
        eodProjectDetail,
        eodObjectsTreeviewInput,
        eodProducttypesProductsSelector,
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        },
        hideService: {
            type: Boolean,
            default: false
        },
        activeJob: {
            type: Object,
            default: null
        },
        defaultPlanning: {
            type: Boolean,
            default: false
        },
        nameAppend: String
    },
    mounted() {
        this.$emit('saving', false);

        this.reportPreviewLanguage = this.$eod.getDefaultLanguage().value;

        if (this.activeJob) {
            this.loadFromActiveJob();
        } else if (this.value && this.value.id) {
            this.editTask(this.value.id);
        } else {
            this.createTask(this.value);
        }
    },
    data: () => ({
        availabilityError: false,
        isCreateProjectDialogVisible: false,
        isCreateProjectDialogLoading: false,
        isWorkflowLoading: false,
        createProjectItem:{},
        reportPreviewLoading:false,
        reportPreviewTemplate:null,
        reportPreviewLanguage:'nl',
        reportPreviewResponse: null,
        reportAutomationTabs: 0,
        topExpansionPanels: [],
        createWorkflowData: null,
        createWorkflowSuccess: false,
        forms_per_page_open: null,
        stepperStep: null,
        stepperPages: [],
        currentStepperStep: 1,
        isStepReadonly: false,
        errors: {},        
        task: null,
        companyProject: [],
        services: [],
        workflowJob: null,
        taskNameChanged: false,
        step: 1,
        cell: null,
        workflowjob_response: null,
        activeTemplateIndex: null,
        activeCellIndex: 0,
        forms_per_page: [],
        forms_jobtype_suggestions: [],
        taskTypeProducts: [],
        taskTypeProductTypes: [],
        jobTypeTemplates: [],
        settings: require('./../classes/settings'),
        taskFields: [
            'id', 'name', 'category', 'route{id}', 'owner{id}', 'details', 'estimatedDuration', 'stepType', 'skills{id name}', 'stepId', 'stepName', 'dueRequestDate', 'mustBePlannedOnRequestDate', 'lat', 'lon', 'postalCode', 'externalIds{name id}', 'storageObjects{id key name}', 'responses{id reportedAt timetrackings{organizationId userId workTypeId duration startedAt comment isInvoicable} comments{organizationId userId content images createdAt} measurements{time value comments{userId content organizationId createdAt} storageObjects{id key name} measurementType{id} product{id} organization{id} company{id} project{id} owner{id} response{id} service{id}}}', 'templates{id name type object content}', 'description', 'dueBeforeDate', 'dueAfterDate', 'statusCode', 'company{id name}', 'taskType{id name estimatedDuration}', 'jobType{id}', 'service{id name periodicityCode}', 'project{id name users{id} availabilities{type allDay duration rrule start end title} locations{name streetAddress postalCode region country locality}}', 'articles{id name quantity articleType{id name} depot{id name}}', 'articleTypes{id name quantity}', 'tools{id name quantity toolType{id name} depot{id name}}', 'toolTypes{id name quantity}', 'responsibleGroup{id}', 'responsibleUsers{id}'
        ],
        workflowFields: [
            'id', 'name', 'category', 'route{id}', 'owner{id}', 'details', 'estimatedDuration', 'stepType', 'skills{id name}', 'stepId', 'stepName', 'dueRequestDate', 'mustBePlannedOnRequestDate', 'lat', 'lon', 'postalCode', 'externalIds{name id}', 'storageObjects{id key name}', 'templates{id name type object content}', 'description', 'dueBeforeDate', 'dueAfterDate', 'statusCode', 'company{id name}', 'taskType{id name estimatedDuration}', 'jobType{id}', 'service{id name periodicityCode}', 'project{id name users{id} availabilities{type allDay duration rrule start end title} locations{name streetAddress postalCode region country locality}}', 'products{id name description code quantity stockLocation{id} productType{unit{name}}}', 'productTypes{id name description quantity stockLocation{id} unit{name}}', 'responsibleGroup{id}', 'responsibleUsers{id}'
        ],
        addTrackingDialog: false,
        newTracking: {},
        dueRequestDateMenu: false,
        formLoaded: false,
        prevResponse: null,
        workflowjob_response_changed: false,
        workflowEvents: [],
        totalEventsCount: null,
        totalProductsCount: null,
        dataconnectorJobtypeDialogShow: false,
        dataconnectorJobtypeDialogLoading: false,
        dataconnectorJobtypeDialogUser: null,
        dataconnectorJobtypeDialogOwner: null,
    }),
    methods: {
        deleteTemplateCell(cellToDelete){
            if(this.workflowJob.templates){
                for (let i = 0; i < this.workflowJob.templates.length; i++) {
                    const template = this.workflowJob.templates[i];
                    if(template.type == 'FORM'){
                        for (let pageIndex = 0; pageIndex < template.content.pages.length; pageIndex++) {
                            const page = template.content.pages[pageIndex];
                            for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                                const cell = page.cells[cellIndex];
                                if(cell.id == cellToDelete.id){
                                    this.workflowJob.templates[i].content.pages[pageIndex].cells.splice(cellIndex, 1);
                                    this.$nextTick(() => {
                                        this.$forceUpdate();
                                    });
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        },
        async createProject(){
            this.isCreateProjectDialogLoading = true;
            const project = new Project(this.createProjectItem);
            const saveData = project.getSaveData();
            const newProjectId = await this.$eod.save('Project', saveData).then(response => {
                if(response && response.data && response.data.data && response.data.data.createProject){
                    return response.data.data.createProject.id;
                }

                return null;
            })

            if(newProjectId){
                this.projectsChanged(newProjectId);
                this.isCreateProjectDialogVisible = false;
            }

            this.createProjectItem = {};
            this.isCreateProjectDialogLoading = false;
        },
        getProjectSearchFilter(){
            if(!this.task){
                return null;
            }

            if(!this.task.projectId){
                return null;
            }

            const filter = this.$helper.getArrayItemByProperty(Product.searchConfig, 'label', 'Project');

            return [{
                config: filter,
                result: {
                    id: this.task.project.id,
                    name: this.task.project.name,
                    search_title:this.task.project.name
                }
            }]
        },
        setFormTemplate(value){
            for (let i = 0; i < this.workflowJob.templates.length; i++) {
                const template = this.workflowJob.templates[i];
                if(template.type == 'FORM'){
                    this.workflowJob.templates[i] = value;
                    return;
                }
            }
        },
        getFormTemplate(workflowJob){
            if(workflowJob.templates){
                for (let i = 0; i < workflowJob.templates.length; i++) {
                    const template = workflowJob.templates[i];
                    if(template.type == 'FORM'){
                        return template;
                    }
                }
            }
            return null;
        },
        changeReportLanguage(lang){
            this.reportPreviewLanguage = lang;
            this.reportPreviewTemplate = null;
            this.updateReportTab(1);
        },
        async updateReportTab(index){
            if(index == 1 && !this.reportPreviewTemplate){
                this.reportPreviewLoading = true;
                const formCellId = this.task.templates[this.activeTemplateIndex].content.pages[this.step].cells[0].settings.formCellId;
                const formCellInfo = this.getPageIndexCellIndexFromCell({id:formCellId});
                const formCell = this.task.templates[this.activeTemplateIndex].content.pages[formCellInfo.pageIndex].cells[formCellInfo.cellIndex];

                if(!this.reportPreviewResponse){
                    this.reportPreviewResponse = await this.$eod.get('responses', ['id'], {
                        where: [
                            {
                                column: 'jobId',
                                operator: '=',
                                value:formCell.jobId
                            }
                        ]
                    }).then(response => {
                        if(response && response.data.data && response.data.data.responses && response.data.data.responses.edges[0]){
                            return response.data.data.responses.edges[0];
                        }

                        return null;
                    });

                    if(!this.reportPreviewResponse){
                        return false;
                    }
                }

                const reportTemplate = await this.$eod.get('templates', ['id', 'name','content'], {
                    whereInRelated:[
                        {
                            relation: 'responses_templates',
                            column: 'response_id',
                            array: [this.reportPreviewResponse.id]
                        }
                    ],
                    where:[
                        {
                            column: 'type',
                            operator: '=',
                            value: 'RESPONSE_REPORT'
                        }
                    ]
                }).then(response => {
                    if(response && response.data.data && response.data.data.templates && response.data.data.templates.edges[0]){
                        return response.data.data.templates.edges[0];
                    }

                    return null;
                });

                let customReportClass = null;
                if(this.task.templates[this.activeTemplateIndex].content.pages[this.step]){
                    const nextStep = this.task.templates[this.activeTemplateIndex].content.pages[this.step];
                    if(nextStep.cells && nextStep.cells[0] && nextStep.cells[0].typeId == 'report'){
                        const reportListItemId = nextStep.cells[0].settings.reportListItemId;
                        if(reportListItemId){
                            const listItem = await this.$eod.getById('listItem', reportListItemId, ['id','name', 'fields{name}']).then(response => {return response.data.data.listItem});
                            if(listItem){
                                if(listItem.fields && listItem.fields[0]){
                                    customReportClass = listItem.fields[0].name;
                                }
                                
                            }
                        }
                    }
                }

                if(reportTemplate){
                    this.updateReportPreview(reportTemplate);
                }else{
                    this.$eod.generateResponseReportTemplate(this.reportPreviewResponse.id, {
                        customReportClass: customReportClass
                    }).then(response => {
                        if (response.data.content) {
                            this.updateReportPreview(response.data);
                        }
                    });
                }
            }      
        },
        async updateReportPreview(template){
            this.reportPreviewTemplate = template;
            this.$nextTick(() => {
                this.$refs.preview_form.submit();
                this.reportPreviewLoading = true;
            });   
        },
        storageSearchFilters(){
            const filters = [];
            if(this.task){
                if(this.task.project && this.task.project.id){
                    filters.push({
                        field: 'projectId',
                        value: this.task.project.id,
                        name: this.task.project.name
                    });
                }
            }

            return filters;
        },
        async dataconnectorJobtypeDialogUserChanged(val){
            this.dataconnectorJobtypeDialogOwner = null;
            this.dataconnectorJobtypeDialogOwner = await this.$eod.getById('user', val, ['id','templates{id type content jobTypes{id}}'])
                    .then(response => {
                        return response.data.data.user;
                    });
            this.dataconnectorJobtypeDialogUser = val;
        },
        dataconnectorJobtypeDialogShowAction(){
            this.dataconnectorJobtypeDialogUser = null;
            this.dataconnectorJobtypeDialogShow = true;
        },
        async saveDataconnectorConfig(cell){
            this.dataconnectorJobtypeDialogLoading = true;
            const jobTypeId = cell.settings.jobTypeId;

            try {
                const owner = this.dataconnectorJobtypeDialogOwner;    
                
                if(owner){
                    const dataConnector = new DataConnector(owner);
                    const data = await dataConnector.getSaveData();
                    await this.$eod.save('User', data);

                    const jobType = await this.$eod.getById('jobType', jobTypeId, ['id', 'name', 'isConnectable', 'connectableConfig{content}', 'articles{id name quantity articleType{id name}}', 'articleTypes{id name quantity}', 'templates{id type content jobTypes{id}}'])
                        .then(response => {
                            return response.data.data.jobType;
                        });

                    await this.setJobTypeTemplate(jobType, cell);

                    let resolvedUser = null;
                    for (let i = 0; i < this.jobTypeTemplates[jobTypeId].dataConnectorsResolved.length; i++) {
                        const resolved = this.jobTypeTemplates[jobTypeId].dataConnectorsResolved[i];
                        if(resolved.userId == this.dataconnectorJobtypeDialogUser){
                            resolvedUser = resolved;
                            break;
                        }
                    }

                    if(resolvedUser){
                        this.setDataConnectorTemplate(resolvedUser, cell);
                    }
                    
                    this.dataconnectorJobtypeDialogShow = false;

                }
            } catch (e) {
                console.error('error saving template', e);
            } finally {
                this.dataconnectorJobtypeDialogLoading = false;
            }
        },
        addUserToProject(user){
            this.task.project.users.push(user);
            const saveData = this.task.project.getSaveData();

            // Only save id and users, because otherwise fields like 'locations' get saved with properties missing and set to 'null'
            this.$eod.save('Project', {
                id: saveData.id,
                users: saveData.users
            }).then(response => {
                if(this.$refs.mailConfigComponent){
                    this.$refs.mailConfigComponent.resolveContacts();
                }
            });
        },
        taskIsPlanned() {
            if (this.task.dueAfterDate && this.task.dueBeforeDate) {
                return true;
            }

            return false;
        },
        getEventsFilters() {
            return [
                {
                    where: [
                        {
                            column: 'time',
                            operator: '>=',
                            value: '' + this.task.dueAfterDate
                        },
                        {
                            column: 'time',
                            operator: '<=',
                            value: '' + this.task.dueBeforeDate
                        },
                        {
                            column: 'projectId',
                            operator: '=',
                            value: this.task.project.id
                        }
                    ]
                }, {
                    where: [
                        {
                            column: 'time',
                            operator: '<',
                            value: '' + this.task.dueAfterDate
                        },
                        {
                            column: 'status',
                            operator: '=',
                            value: 'open'
                        },
                        {
                            column: 'projectId',
                            operator: '=',
                            value: this.task.project.id
                        }
                    ]
                }];
        },
        taskFormLoaded() {
            this.formLoaded = true;
        },
        responseChanged(val) {
            if (this.prevResponse && this.formLoaded && !_.isEqual(this.prevResponse, val)) {
                this.workflowjob_response_changed = true;
            }
            this.prevResponse = _.cloneDeep(val);

            this.$emit('responseChanged', val);
        },
        showCreateWorkflow(data) {

            let activeResponse = this.workflowjob_response;
            if (this.response) {
                activeResponse = this.response;
            }

            this.$emit('createWorkflow', data, this.task, activeResponse);
        },
        setWorkflowName(name) {
            this.task.name = name;
        },
        storageObjectAdded(storageObject){
            if(!this.task.storageObjects){
                this.task.storageObjects = [];
            }

            this.task.storageObjects.push(storageObject);
        },
        storageObjectUpdated(storageObjects){
            this.task.storageObjects = storageObjects;
        },
        updateTaskProperty(property, val) {
            if (this.value) {
                let task = this.value;
                task[property] = val;

                

                this.$emit('input', task);
            }
        },
        getFormCell() {
            const formCell = this.getFormCellIndex();
            if (!formCell) {
                return null;
            }

            return this.task.templates[this.activeTemplateIndex].content.pages[formCell.pageIndex].cells[formCell.cellIndex];
        },
        taskHasPlanStep(){
            if(this.task.templates[this.activeTemplateIndex] && !this.defaultPlanning){
                const pages = this.task.templates[this.activeTemplateIndex].content.pages;
                for (let i = 0; i < pages.length; i++) {
                    const page = pages[i];
                    for (let i = 0; i < page.cells.length; i++) {
                        const cell = page.cells[i];
                        if(cell.settings.type == 'plan'){
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        getFormCellIndex() {

            if (this.cell.settings.type == 'plan') {
                const cell = this.getLinkedStepFormCell();
                return this.getPageIndexCellIndexFromCell(cell);
            } else if (this.cell.settings.type == 'verify') {
                let linkedCell = this.getLinkedStepFormCell();
                while (linkedCell.settings.verify && linkedCell.settings.cellId) {
                    linkedCell = this.getLinkedStepFormCell(linkedCell.settings.cellId);
                }
                return this.getPageIndexCellIndexFromCell(linkedCell);
            } else if (this.cell.typeId == 'form') {
                return this.getPageIndexCellIndexFromCell(this.cell);
            }

            return null;
        },
        addTracking() {
            this.newTracking = {}
            this.addTrackingDialog = true;
        },
        editTracking(index) {
            let tracking = _.cloneDeep(this.workflowjob_response.timetrackings[index]);
            tracking.index = index;
            this.newTracking = tracking;
            this.addTrackingDialog = true;
        },
        saveTracking() {
            if (this.newTracking.index != null) {
                const index = this.newTracking.index;
                delete this.newTracking.index;
                this.workflowjob_response.timetrackings[index] = this.newTracking;
            } else {
                this.workflowjob_response.timetrackings.push(this.newTracking);
            }
            this.addTrackingDialog = false;
        },
        async setJobType(value) {
            this.setJobTypeTemplate(value, {
                pageIndex: this.step - 1,
                index: this.activeCellIndex
            });

            let jobData = await this.workflowJob.getSaveData();

            const templateId = await this.getJobTemplate(this.getFormCell(), value.id);
            jobData.templates = [{ id: templateId, default: true }];
            jobData.jobTypeId = value.id;

            this.workflowJob = await this.updateTask(jobData);

            const taskData = await this.task.getSaveData();
            this.task = await this.updateTask(taskData);

            this.loadWorkflowJob();
            this.loadForm();
        },
        getDataConnectorResolved(templateId, cell) {
            for (let i = 0; i < this.jobTypeTemplates[cell.settings.jobTypeId].dataConnectorsResolved.length; i++) {
                const dataConnector = this.jobTypeTemplates[cell.settings.jobTypeId].dataConnectorsResolved[i];
                if (dataConnector.id == templateId) {
                    return dataConnector;
                }
            }
            return null;
        },
        setDataConnectorTemplate(val, cell) {

            const template = this.jobTypeTemplates[cell.settings.jobTypeId].dataConnectors[val.id];

            this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].ownerId = val.userId;
            this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.jobTypeConfigTemplateId = val.id;

            if (template.content.variables) {
                for (const variableId in template.content.variables) {
                    const productId = template.content.variables[variableId];
                    this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultVariables[variableId] = { id: productId };
                }
            }

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        async setJobTypeTemplate(val, cell) {
            this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.jobTypeId = val ? val.id : null;

            this.jobTypeTemplates[val.id] = {
                ...val,
                ...{
                    template: null,
                    envVariables: [],
                    dataConnectors: {},
                    dataConnectorsResolved: []
                }
            }

            if(!this.task.estimatedDuration && val && val.estimatedDuration){
                this.task.estimatedDuration = val.estimatedDuration;
            }

            if(val.connectableConfig && val.connectableConfig.content && val.connectableConfig.content != ''){
                for (let i = 0; i < val.connectableConfig.content.length; i++) {
                    const node = val.connectableConfig.content[i];
                    if(node.type == 'tab'){
                        if(node.env){
                            if (!this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultEnvVariables) {
                                this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultEnvVariables = {};
                            }

                            for (let j = 0; j < node.env.length; j++) {
                                const envVariable = node.env[j];
                                if(envVariable.name.startsWith('EOD_USER_')){
                                    this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultEnvVariables[envVariable.name] = envVariable;
                                    this.jobTypeTemplates[val.id].envVariables.push(envVariable);
                                }
                            }
                        }
                        break;
                    }
                }
            }

            const dataConnectorIds = {};

            if (val && val.templates) {
                    for (let i = 0; i < val.templates.length; i++) {
                        const template = val.templates[i];
                        if (template.type == 'FORM') {
                            this.jobTypeTemplates[val.id].template = template;

                            if (!this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultVariables) {
                                this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultVariables = {};
                                if (template.content.variables) {
                                    for (let j = 0; j < template.content.variables.length; j++) {
                                        const variable = template.content.variables[j];
                                        this.task.templates[this.activeTemplateIndex].content.pages[cell.pageIndex].cells[cell.index].settings.defaultVariables[variable.value] = variable.product;
                                    }
                                }
                            }
                        } else if (template.type == "JOB_TYPE_CONFIG") {
                            this.jobTypeTemplates[val.id].dataConnectors[template.id] = template;
                            if (template.users) {
                                for (let j = 0; j < template.users.length; j++) {
                                    const templateUser = template.users[j];
                                    dataConnectorIds[templateUser.id] = template.id;
                                }
                            }
                        }
                    }

                if (Object.keys(dataConnectorIds).length > 0) {
                    this.jobTypeTemplates[val.id].dataConnectorsResolved = await this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username', 'email'], {
                        whereIn: {
                            column: 'id',
                            array: Object.keys(dataConnectorIds)
                        }
                    })
                        .then(result => {
                            let count = result.data.data.resolveUsers.edges.length;
                            const users = [];
                            for (let i = 0; i < count; i++) {
                                const user = new User(result.data.data.resolveUsers.edges[i]);
                                users.push({
                                    id: dataConnectorIds[user.id],
                                    userId: user.id,
                                    text: user.getFullName()
                                })
                            }

                            return users;
                        });
                }

                if (cell.planPageIndex) {
                    if (!this.task.templates[this.activeTemplateIndex].content.pages[cell.planPageIndex].cells[cell.planCellIndex].settings.articles && val.articles && val.articles.length > 0) {
                        this.task.templates[this.activeTemplateIndex].content.pages[cell.planPageIndex].cells[cell.planCellIndex].settings.articles = val.articles;
                    }

                    if (!this.task.templates[this.activeTemplateIndex].content.pages[cell.planPageIndex].cells[cell.planCellIndex].settings.articleTypes && val.articleTypes && val.articleTypes.length > 0) {
                        this.task.templates[this.activeTemplateIndex].content.pages[cell.planPageIndex].cells[cell.planCellIndex].settings.articleTypes = val.articleTypes;
                    }
                }
            }

            this.$forceUpdate();
        },
        getJobTypeTemplateVariable(jobTypeId, variableId) {
            if (this.jobTypeTemplates[jobTypeId] && this.jobTypeTemplates[jobTypeId].template) {
                if (this.jobTypeTemplates[jobTypeId].template.content.variables) {
                    for (let i = 0; i < this.jobTypeTemplates[jobTypeId].template.content.variables.length; i++) {
                        const variable = this.jobTypeTemplates[jobTypeId].template.content.variables[i];
                        if (variable.value == variableId) {
                            return variable;
                        }
                    }
                }
            }
        },
        dueAfterDateChanged(val) {
            if (this.workflowJob.dueBeforeDate && this.$moment(this.workflowJob.dueBeforeDate) > this.$moment(this.workflowJob.dueAfterDate)) {
                let diff = this.$moment(this.workflowJob.dueBeforeDate).diff(this.$moment(this.workflowJob.dueAfterDate), 'minutes');
                this.workflowJob.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').add(diff, 'minutes').format('YYYY-MM-DD HH:mm');
            } else if (!this.workflowJob.dueBeforeDate && this.workflowJob.estimatedDuration) {
                this.workflowJob.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').add(this.workflowJob.estimatedDuration, 'milliseconds').format('YYYY-MM-DD HH:mm');
            } else if (!this.workflowJob.dueBeforeDate && this.task.taskType.estimatedDuration) {
                this.workflowJob.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').add(this.task.taskType.estimatedDuration, 'milliseconds').format('YYYY-MM-DD HH:mm');
            }
            this.workflowJob.dueAfterDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');

            this.checkWorkflowJobAvailability();

            this.$forceUpdate();
        },
        dueBeforeDateChanged(val) {
            this.workflowJob.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            this.checkWorkflowJobAvailability();
        },
        checkWorkflowJobAvailability(){
            this.availabilityError = false;
            if(this.workflowJob.dueAfterDate){
                const dateUntil = this.workflowJob.dueBeforeDate?this.workflowJob.dueBeforeDate:this.$moment(this.workflowJob.dueAfterDate).add(1, 'hours').toISOString()
                console.log(this.workflowJob.project);
                if(!this.workflowJob.project.isAvailable(this.workflowJob.dueAfterDate, dateUntil)){
                    this.availabilityError = true;
                }
            }
        },
        editTask(id) {
            this.$eod.getById('task', id, this.workflowFields)
                .then(response => {
                    this.task = new Task(response.data.data.task);
                    
                    // When task has errors, open 'Planning' tab
                    if (this.task.details) {
                        this.topExpansionPanels.push(2);
                    } else if (!this.task.description || this.task.description == ''){
                        this.topExpansionPanels = [0];
                    }

                    this.loadWorkflow();
                });
        },
        loadFromActiveJob() {
            this.task = new Task(this.activeJob.parent);
            this.workflowJob = this.activeJob;
            this.loadWorkflow();
        },
        createTask(data) {
            if (!data) {
                data = {};
            }

            if (!data.category) {
                data.category = 'TASK';
            }

            if (!data.taskTypeId) {
                const defaultTaskTypeSetting = this.$eod.getOrganizationSetting('tasks.taskType.default.portal');
                if (defaultTaskTypeSetting) {
                    data.taskTypeId = defaultTaskTypeSetting.value;
                }
            }

            if(data.name){
                this.taskNameChanged = true;
            }

            this.task = new Task(data);

            if (this.task.company && this.task.project) {
                this.loadServices();
            }
        },
        async taskTypesChanged(taskType) {

            this.errors = {};

            this.task.taskTypeId = taskType.id;
            this.task.statusCode = 'in_progress';

            // Get taskType template and assign to task
            if (!this.task.templates) {
                this.task.templates = [];
            } else {
                for (let i = 0; i < this.task.templates.length; i++) {
                    const template = this.task.templates[i];
                    if (template.type == 'WORKFLOW') {
                        this.task.templates.splice(i, 1);
                    }
                }
            }

            for (let i = 0; i < taskType.templates.length; i++) {
                let template = taskType.templates[i];
                if (template.type == 'WORKFLOW') {
                    template.object = 'TASK';
                    delete template.id;
                    this.task.templates.push(template);
                    this.activeTemplateIndex = this.task.templates.length - 1;
                    this.task.stepId = this.task.templates[this.activeTemplateIndex].content.pages[0].id;
                    this.task.stepName = this.task.templates[this.activeTemplateIndex].content.pages[0].name;
                    this.task.stepType = 'START';
                    this.task.templates[this.activeTemplateIndex].content.pages[0].cells[0].active = true;
                    this.cell = this.task.templates[this.activeTemplateIndex].content.pages[0].cells[0];
                    this.task.templates[this.activeTemplateIndex].content.skipPlan = this.defaultPlanning || this.mobile;
                    break;
                }
            }

            if (!this.taskNameChanged) {
                if (taskType) {

                    let append = '';

                    if (this.nameAppend) {
                        append = this.nameAppend;
                    }

                    await this.$eod.loadUserOrganization();
                    const taskExternalId = this.$eod.getOrganizationSetting('tasks.external.id');
                    if (taskExternalId && taskExternalId.value && taskExternalId.value != '' && taskExternalId.value != 'NaN') {
                        append = ' [' + taskExternalId.value + ']';

                        this.task.externalIds = [{
                            'name': 'ID',
                            'id': taskExternalId.value
                        }];
                    }
                    this.task.name = taskType.name + append;

                }
            }

            this.loadStepperPages();
            this.loadDynamicForms();

            this.$emit('input', this.task);
        },
        getActiveTemplateIndex() {
            if(this.task.templates){
                for (let i = 0; i < this.task.templates.length; i++) {
                    const template = this.task.templates[i];
                    if (template.type == 'WORKFLOW') {
                        return i;
                    }
                }
            }
            
            return null;
        },
        getActiveStepIndex() {
            if(this.stepperStep != null){
                return this.stepperStep;
            }

            for (let j = 0; j < this.task.templates[this.activeTemplateIndex].content.pages.length; j++) {
                const page = this.task.templates[this.activeTemplateIndex].content.pages[j];

                if (this.task.stepId) {
                    if (page.id == this.task.stepId) {
                        return j + 1;
                    }
                } else if (page.cells.length > 0) {
                    for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                        const cell = page.cells[cellIndex];
                        if (cell.active) {
                            return j + 1;
                        }
                    }
                } else if (page.active) {
                    return j + 1;
                }

            }

            return null;
        },
        getActiveCellIndex() {
            return 0;
        },
        stepChanged(newStep){
            this.cell = null;
            this.isStepReadonly = newStep < this.currentStepperStep;
            this.loadWorkflow();
        },
        loadStepperPages() {
            let steps = [];

            if(this.task.templates[this.activeTemplateIndex]){
                for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages.length; i++) {
                    const page = this.task.templates[this.activeTemplateIndex].content.pages[i];
                    if (page.id == this.task.stepId) {
                        this.stepperStep = i + 1;
                        this.currentStepperStep = i + 1;
                    }

                    steps.push(page);
                }
            }
            

            this.stepperPages = steps;

        },
        getActiveTemplate() {
            return new Template(this.task.templates[this.activeTemplateIndex]);
        },
        async loadWorkflow() {
            this.isWorkflowLoading = true;
            this.activeTemplateIndex = this.getActiveTemplateIndex();
            this.step = this.getActiveStepIndex();
            this.activeCellIndex = this.getActiveCellIndex();

            this.loadStepperPages();

            for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages[this.step - 1].cells.length; i++) {
                const cell = this.task.templates[this.activeTemplateIndex].content.pages[this.step - 1].cells[i];
                if (cell) {
                    this.cell = cell;
                    this.$emit('loaded', this.cell);
                    break;
                }
            }

            if(!this.activeJob){
                await this.loadWorkflowJob();
            }
            
            if ((this.workflowJob.stepType == 'START' && this.task.projectId) || this.workflowJob.stepType == 'PLAN') {
                this.loadServices();

                if (!this.activeJob) {
                    this.loadDynamicForms();
                }
            }

            this.loadForm();
            if (this.taskIsPlanned()) {
                this.countEvents();
            }

            this.isWorkflowLoading = false;
        },
        async countEvents() {

            const filters = this.getEventsFilters();

            let count = 0;
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                count += await this.$eod.count('countEvents', filter).then(response => {
                    return response.data.data.countEvents.totalCount;
                });
            }

            this.totalEventsCount = count;
        },
        loadServices() {
            this.$eod.get('services', ['id', 'name', 'description'], {
                where: [
                    {
                        column: 'companyId',
                        operator: '=',
                        value: this.task.companyId,
                        chain: 'or'
                    },
                ],
                orderBy: { column: 'name', type: 'asc' }
            })
                .then(response => {
                    if (response.data.data.services.edges) {
                        this.services = response.data.data.services.edges;
                        if (this.task.company && this.task.company.defaultService) {
                            this.task.serviceId = this.task.company.defaultService.id;
                        }

                        if (!this.task.serviceId && this.services[0]) {
                            this.task.serviceId = this.services[0].id;
                        }

                        this.serviceChanged();
                    }
                });
        },
        async projectsChanged(projectId) {

            this.errors = {};
            this.availabilityError = false;

            if(!projectId){
                this.task.serviceId = null;
                this.task.companyId = null;
                this.task.projectId = null;
                this.task.lat = null;
                this.task.lon = null;
                this.task.postalCode = null;
                this.forms_per_page = [];
                return false;
            }

            const project = await this.$eod.getById('project', projectId, ['id', 'name', 'description', 'locations{lat lon postalCode}', 'company{id name description defaultService{id}}', 'availabilities{type allDay duration rrule start end title}'])
                .then(response => {
                    if(response.data && response.data.data && response.data.data.project){
                        return new Project(response.data.data.project);
                    }

                    return null;
                }).catch(() => {
                    return null;
                });

            if(this.task.dueAfterDate){
                const dateUntil = this.task.dueBeforeDate?this.task.dueBeforeDate:this.$moment(this.task.dueAfterDate).add(1, 'hours').toISOString()
                if(!project.isAvailable(this.task.dueAfterDate, dateUntil)){
                    this.availabilityError = true;
                }
            }
            
            if(!project){
                this.errors.projectLocation = 'Fout bij het laden van het project.';
                return false;
            }

            if (this.taskHasPlanStep() && this.$eod.getOrganizationSetting('planning.optimizeWaypoints') && this.$eod.getOrganizationSetting('planning.optimizeWaypoints').value == true && !(project.locations && project.locations[0] && project.locations[0].lat && project.locations[0].lon)) {
                this.task.serviceId = null;
                this.task.companyId = null;
                this.task.projectId = null;
                this.task.lat = null;
                this.task.lon = null;
                this.task.postalCode = null;
                this.forms_per_page = [];
                this.errors.projectLocation = 'Dit project heeft geen locatie! Gelieve eerst een locatie in te geven bij het project!';
                return false;
            }

            this.task.companyId = project.company.id;
            this.task.projectId = project.id;
            this.task.company = project.company;
            this.task.project = project;

            if (project.locations && project.locations[0]) {
                this.task.lat = project.locations[0].lat;
                this.task.lon = project.locations[0].lon;
                this.task.postalCode = project.locations[0].postalCode;
            } else {
                this.task.lat = null;
                this.task.lon = null;
                this.task.postalCode = null;
            }

            this.task.serviceId = null;
            this.loadServices();
        },
        serviceChanged() {
            this.errors = {};
            this.loadDynamicForms();
        },
        createInitialJob() {
            let jobData = Object.assign({}, this.task);
            delete jobData.id;
            delete jobData.responses;
            delete jobData.templates;

            jobData = {
                ...jobData,
                //parentTaskId: this.task.id,
                name: 'Start',
                category: 'JOB',
                isCompleted: false,
                statusCode: 'in_progress',
                stepType: 'START',
            }

            return new Task(jobData);
        },
        setProducts(products) {
            this.workflowJob.products = products;
        },
        async loadWorkflowJob() {
            let jobId = null;
            if (this.cell && this.cell.jobId) {
                jobId = this.cell.jobId;
            } else {
                jobId = this.task.templates[this.activeTemplateIndex].content.pages[this.step - 1].jobId;
            }

            await this.$eod.getById('task', jobId, this.taskFields).then(response => {
                if (response.data.data.task) {
                    this.workflowJob = new Task(response.data.data.task);
                    if(this.workflowJob.stepType == 'PLAN'){
                        if (!this.workflowJob.dueAfterDate) {
                            const linkedCell = this.getLinkedStepFormCell();
                            if (linkedCell && linkedCell.settings.requestDate) {
                                let dueAfterDate = this.$moment(linkedCell.settings.requestDate, 'YYYY-MM-DD');
                                let now = new Date();
                                dueAfterDate.set({
                                    hour: now.getHours(),
                                    minute: now.getMinutes()
                                });
                                this.workflowJob.dueAfterDate = dueAfterDate.toISOString();
                                this.workflowJob.dueBeforeDate = dueAfterDate.add(1, 'hours').toISOString();
                            }
                        }
                    }

                    const productTypeRelations = ['article'];
                    this.totalProductsCount = 0;
                    for (let i = 0; i < productTypeRelations.length; i++) {
                        const relation = productTypeRelations[i];

                        if(this.workflowJob[relation+'s'] && this.workflowJob[relation+'s'][0]){
                            this.totalProductsCount += this.workflowJob[relation+'s'].length;
                        }
                        if(this.workflowJob[relation+'Types'] && this.workflowJob[relation+'Types'][0]){
                            this.totalProductsCount += this.workflowJob[relation+'Types'].length;
                            this.topExpansionPanels.push(4);
                        }
                    }                    
                }
            });
        },
        async loadDynamicForms() {
            let dynamic_form_cells = [];

            if (!this.task.project.id) {
                return false;
            }

            // Get default jobTypeIds of project
            let jobTypes = await this.$eod.get('jobTypes', ['id', 'defaultService{id}', 'estimatedDuration'], {
                where: [
                    {
                        'column': 'defaultProjectId',
                        'operator': '=',
                        'value': this.task.project.id
                    }
                ]
            }).then(response => {
                return response.data.data.jobTypes.edges;
            });

            if (this.activeTemplateIndex != null && this.task.templates[this.activeTemplateIndex]) {
                for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages.length; i++) {
                    const page = this.task.templates[this.activeTemplateIndex].content.pages[i];
                    for (let j = 0; j < page.cells.length; j++) {
                        const cell = page.cells[j];
                        if (cell.typeId == 'form') {
                            if (cell.settings.type == 'dynamic') {
                                if (this.forms_per_page_open == null) {
                                    this.forms_per_page_open = j;
                                }

                                if (jobTypes.length > 0 && !this.task.templates[this.activeTemplateIndex].content.pages[i].cells[j].settings.jobTypeId) {
                                    this.task.templates[this.activeTemplateIndex].content.pages[i].cells[j].settings.jobTypeId = jobTypes[0].id;
                                    cell.settings.jobTypeId = jobTypes[0].id;

                                    if (jobTypes[0].defaultService) {
                                        // Check if default Service in company services
                                        for (let serviceIndex = 0; serviceIndex < this.services.length; serviceIndex++) {
                                            const service = this.services[serviceIndex];
                                            if (service.id == jobTypes[0].defaultService.id) {
                                                this.task.serviceId = jobTypes[0].defaultService.id;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }

                            if (cell.jobId) {
                                cell.job = await this.$eod.getById('task', cell.jobId, ['id', 'name', 'dueBeforeDate', 'dueAfterDate', 'stepType', 'responsibleUsers{id}'])
                                    .then(response => {
                                        return new Task(response.data.data.task);
                                    });
                            }
                            if (cell.settings.jobTypeId) {
                                cell.jobType = await this.$eod.getById('jobType', cell.settings.jobTypeId, ['id', 'name', 'isConnectable', 'estimatedDuration', 'connectableConfig{content}', 'articles{id name quantity articleType{id name}}', 'articleTypes{id name quantity}', 'templates{id type content users{id} jobTypes{id}}'])
                                    .then(response => {
                                        return response.data.data.jobType;
                                    });
                            }

                            if (cell.settings.plan) {
                                const plan_cell = this.getLinkedPlanCell(cell);
                                const pageCellIndex = this.getPageIndexCellIndexFromCell(plan_cell);
                                cell.planPageIndex = pageCellIndex.pageIndex;
                                cell.planCellIndex = pageCellIndex.cellIndex;
                            }


                            cell.pageIndex = i;
                            cell.index = j;
                            dynamic_form_cells.push(cell);
                        }
                    }
                }
            }

            let jobTypeIds = [];
            for (let i = 0; i < jobTypes.length; i++) {
                const jobType = jobTypes[i];
                jobTypeIds.push(jobType.id);
            }

            if(dynamic_form_cells && dynamic_form_cells[0] && dynamic_form_cells[0].jobType){
                this.setJobTypeTemplate(dynamic_form_cells[0].jobType, dynamic_form_cells[0]);
            }

            this.forms_jobtype_suggestions = jobTypeIds;
            this.forms_per_page = dynamic_form_cells;
        },
        getLinkedStepFormCell(cellId) {
            if (!cellId) {
                cellId = this.cell.settings.cellId;
            }
            for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages.length; i++) {
                const page = this.task.templates[this.activeTemplateIndex].content.pages[i];
                for (let j = 0; j < page.cells.length; j++) {
                    const cell = page.cells[j];
                    if (cell.id == cellId) {
                        return cell;
                    }
                }
            }
        },
        getLinkedPlanCell(form_cell) {
            for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages.length; i++) {
                const page = this.task.templates[this.activeTemplateIndex].content.pages[i];
                for (let j = 0; j < page.cells.length; j++) {
                    const cell = page.cells[j];
                    if (cell.settings.type == 'plan' && cell.settings.cellId == form_cell.id) {
                        return cell;
                    }
                }
            }
        },
        async loadForm() {
            if (this.workflowJob && this.workflowJob.responses && this.workflowJob.responses[0]) {
                this.workflowjob_response = this.workflowJob.responses[0];
                this.workflowjob_response.job = this.workflowJob;
            } else {
                this.workflowjob_response = {
                    isActive: true,
                    user: this.$eod.getUser(),
                    isProcessed: false,
                    source: this.$helper.getSystemInfo(),
                    job: this.workflowJob ? this.workflowJob : null,
                    products: [],
                    productTypes: [],
                    measurements: [],
                    timetrackings: [],
                    comments: []
                }
            }
        },
        updateTask(data) {
            return this.$eod.save('Task', data, {
                create:{
                    returnfields: this.taskFields
                },
                update:{
                    returnfields: this.taskFields
                }
            }).then(response => {
                if (response.data.data.createTask) {
                    return response.data.data.createTask;
                } else {
                    return response.data.data.updateTask;
                }
            }).then(data => {
                return new Task(data);
            });
        },
        duplicateResponses(fromJobId, toJobId) {
            return this.$eod.get('responses', ['id reportedAt comments{organizationId userId content images createdAt} timetrackings{organizationId userId startedAt comment duration isInvoicable workTypeId} metrics{cellId productId value field comment message images measurementTypeId}'], {
                where: [
                    {
                        column: 'jobId',
                        operator: '=',
                        value: fromJobId
                    }
                ]
            }).then(response => {
                if (response.data.data.responses && response.data.data.responses.edges) {
                    return response.data.data.responses.edges;
                }

                return [];
            }).then(async responses => {
                for (let i = 0; i < responses.length; i++) {
                    const existing_response = responses[i];
                    let response = {
                        isActive: true,
                        taskId: this.task.id,
                        jobId: toJobId,
                        userId: this.$eod.getUser().id,
                        statusCode: 'in_progress',
                        isProcessed: false,
                        metrics: existing_response.metrics,
                        source: this.$helper.getSystemInfo(),
                        timetrackings: existing_response.timetrackings,
                        comments: existing_response.comments,
                        reportedAt: existing_response.reportedAt,
                        products: [],
                        productTypes: [],
                    };

                    await this.$eod.save('Response', response);
                }

                return true;
            });
        },
        validate() {

            let errors = {};

            if (!this.task.name || this.task.name == '') {
                errors.name = 'Gelieve een naam in te geven!';
            }

            if(this.$refs && this.$refs.taskForm){
                return this.$refs.taskForm.validate();
            }else if (this.task.stepType == 'START') {
                if (!this.task.taskTypeId) {
                    errors.taskTypeId = 'Gelieve een workflow te selecteren!';
                }

                if (!this.task.projectId) {
                    errors.projectId = 'Gelieve een project te selecteren!';
                }

                if (!this.task.serviceId) {
                    errors.serviceId = 'Gelieve een dienst te selecteren!';
                }

                // Add extra check if plan step exists
                /*if (!this.task.templates[this.activeTemplateIndex].content.skipPlan) {
                    if (!this.task.dueRequestDate && this.$eod.getOrganizationSetting('planning.optimizeWaypoints') && this.$eod.getOrganizationSetting('planning.optimizeWaypoints').value == true) {
                        errors.dueRequestDate = 'Gelieve een gewenste planningsdatum te selecteren!';
                    }
                }*/

                if (this.forms_per_page) {
                    for (let i = 0; i < this.forms_per_page.length; i++) {
                        const form_cell = this.forms_per_page[i];
                        const cell = this.task.templates[this.activeTemplateIndex].content.pages[form_cell.pageIndex].cells[form_cell.index];

                        if (this.jobTypeTemplates[form_cell.settings.jobTypeId] && this.jobTypeTemplates[form_cell.settings.jobTypeId].template) {
                            if (this.jobTypeTemplates[form_cell.settings.jobTypeId].dataConnectorsResolved && this.jobTypeTemplates[form_cell.settings.jobTypeId].dataConnectorsResolved.length > 0) {
                                if (!cell.ownerId) {
                                    if (!errors.forms) {
                                        errors.forms = {};
                                    }

                                    errors.forms[cell.id] = 'Gelieve een dataconnector te selecteren!';
                                    this.forms_per_page_open = i;
                                }

                                if (!cell.settings.jobTypeConfigTemplateId) {
                                    if (!errors.forms) {
                                        errors.forms = {};
                                    }

                                    errors.forms[cell.id] = 'Gelieve een dataconnector te selecteren!';
                                    this.forms_per_page_open = i;
                                }
                            }
                        }


                        if (!cell.settings.jobTypeId) {
                            if (!errors.forms) {
                                errors.forms = {};
                            }
                            errors.forms[cell.id] = 'Gelieve een formulier te selecteren!';
                            this.forms_per_page_open = i;
                        }
                    }
                }

            }

            if(this.$refs && this.$refs.form && this.workflowJob && this.workflowJob.stepType == 'PLAN'){
                const valid = this.$refs.form.validate();

                if(!valid){
                    this.topExpansionPanels.push(4);
                }

                return valid;
            }

            this.errors = errors;

            if (Object.keys(errors).length > 0) {
                return false;
            }

            return true;
        },
        incrementExternalId(externalIds) {
            for (let i = 0; i < externalIds.length; i++) {
                const externalId = externalIds[i];
                if (externalId.name == 'ID') {
                    if (!isNaN(externalId.id)) {
                        const nextId = parseInt(externalId.id) + 1;
                        this.$eod.saveOrganizationSetting('tasks.external.id', nextId);
                        return true;
                    }
                }
            }

            return false;
        },
        saveTask(data, isSubmit, options) {

            return new Promise(async (resolve, reject) => {

                if (!this.validate()) {
                    return reject(this.errors);
                }

                if (this.workflowJob) {
                    this.task.dueAfterDate = this.workflowJob.dueAfterDate;    
                    this.task.dueBeforeDate = this.workflowJob.dueBeforeDate;    
                    this.task.skills = this.workflowJob.skills;
                }

                if(this.task.dueAfterDate && this.task.estimatedDuration && !this.task.dueBeforeDate){
                    const momentAfter = this.$moment(this.task.dueAfterDate);
                    this.task.dueBeforeDate = momentAfter.add(this.task.estimatedDuration, 'milliseconds', true);
                }

                let taskData = this.task.getRawData();
                if (data) {
                    taskData = {
                        ...taskData,
                        ...data
                    };
                }

                let jobData = null;
                if( this.workflowJob){
                    jobData = this.workflowJob.getRawData();
                    delete jobData.responses;
                    if (jobData.statusCode && jobData.statusCode == 'to_do') {
                        // Only go to in_progress when form is changed
                        if (this.workflowjob_response_changed) {
                            jobData.statusCode = 'in_progress';
                        }
                    }
                }

                // Delete responses for now (seperate function 'saveForm')
                delete taskData.responses;

                //Saving new storageObjects and link to task
                const newStorageObjects = [];
                if(taskData.storageObjects){
                    for (let i = 0; i < taskData.storageObjects.length; i++) {
                        const storageObject = taskData.storageObjects[i];
                        if(storageObject.id && storageObject.needsCopy){
                            newStorageObjects.push(storageObject);
                            delete taskData.storageObjects[i].needsCopy;
                        }
                    }
                }

                const savedTask = await this.$eod.saveWorkflow({
                    task: taskData,
                    job: jobData
                }, {
                    returnfields: ['workflow{id}', 'job{id}']
                });

                this.task.id = savedTask.workflow.id;

                if(newStorageObjects && newStorageObjects[0]){
                    for (let i = 0; i < newStorageObjects.length; i++) {
                        const storageObject = newStorageObjects[i];
                        await this.$eod.createStorageObject(storageObject.id, {
                            serviceId: this.task.service.id,
                            projectId: this.task.project.id,
                            taskId: savedTask.workflow.id,
                            taskTypeId: this.task.taskType.id,
                            companyId: this.task.company.id,
                            userId: this.$eod.getUser().id,
                        });
                    }
                }

                if (this.workflowjob_response && this.workflowjob_response.measurements.length > 0) {
                    await this.saveForm(isSubmit);
                }

                if(options && options.dontCloseAfter){
                    // Delete items, so fetch all again
                    this.workflowJob = null;
                    this.workflowjob_response = null;

                    this.editTask(savedTask.workflow.id);

                    //await this.loadWorkflow();
                    await this.loadForm();
                }

                return resolve(savedTask);
            });
        },
        save(statusCode, dontCloseAfter) {
            this.$emit('saving', true);

            if (statusCode) {
                this.workflowJob.statusCode = statusCode.value;

                if (statusCode.value == 'on_hold') {
                    this.task.statusCode = 'on_hold';
                } else {
                    this.task.statusCode = 'in_progress';
                }
            }

            this.saveTask(null, false, {
                dontCloseAfter: dontCloseAfter
            }).then(() => {
                if(!dontCloseAfter){
                    this.$emit('saved', this.task, this.workflowJob);
                }
            }).catch(() => {
                // Invalid validation!
            }).finally(() => {
                this.$emit('saving', false);
            });
        },
        fillTemplateDefaults(cellId, template) {
            for (let i = 0; i < this.task.templates[this.activeTemplateIndex].content.pages.length; i++) {
                const page = this.task.templates[this.activeTemplateIndex].content.pages[i];
                for (let j = 0; j < page.cells.length; j++) {
                    const cell = page.cells[j];
                    if (cell.id == cellId) {
                        if (cell.settings.defaultVariant) {
                            template.content.variant = cell.settings.defaultVariant;
                        }
                        if (cell.settings.defaultVariables && template.content.variables && template.content.variables.length > 0) {
                            for (const variableId in cell.settings.defaultVariables) {
                                const product = cell.settings.defaultVariables[variableId];
                                for (let variableIndex = 0; variableIndex < template.content.variables.length; variableIndex++) {
                                    const variable = template.content.variables[variableIndex];
                                    if (variable.value == variableId) {
                                        template.content.variables[variableIndex].product = product;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return template;
        },
        getPageIndexCellIndexFromCell(findCell) {
            for (let pageIndex = 0; pageIndex < this.task.templates[this.activeTemplateIndex].content.pages.length; pageIndex++) {
                const page = this.task.templates[this.activeTemplateIndex].content.pages[pageIndex];
                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                    const cell = page.cells[cellIndex];
                    if (cell.id == findCell.id) {
                        return {
                            pageIndex: pageIndex,
                            cellIndex: cellIndex
                        };
                    }
                }
            }

            return null;
        },
        async reject() {
            this.$emit('saving', true);

            this.$eod.rejectWorkflow(this.task.id).then(response => {
                this.$emit('saved', response);
            }).finally(() => {
                this.$emit('saving', false);
            });
        },
        async getJobTemplate(cell, jobTypeId) {
            let template = await this.duplicateTemplate('jobType', jobTypeId);
            template = this.fillTemplateDefaults(cell.id, template);
            return await this.$eod.save('Template', template)
                .then(response => {
                    return response.data.data.createTemplate.id;
                });
        },
        submit() {

            this.$emit('saving', true);
            this.saveTask(null, true).then(() => {
                return this.$eod.submitWorkflow(this.task.id);
            }).then(response => {
                if (this.workflowJob && this.workflowJob.stepType == 'PLAN' && this.workflowJob.route) {
                    this.workflowJob.route = null;
                    // Remove planning task from route
                    return this.$eod.save('Task', {
                        id: this.workflowJob.id,
                        routeId: null
                    }).then(() => {
                        return response;
                    });
                }
                return response;
            }).then(response => {
                if (response.id) {
                    //this.task = response.workflow;
                    this.$emit('saved', this.task, response);
                }
            }).catch((e) => {
                //Invalid validation
                console.error('error', e);
            }).finally(() => {
                this.$emit('saving', false);
            });
        },
        duplicateTemplate(endpoint, itemId) {
            return this.$eod.getById(endpoint, itemId, ['templates{name description type object content}'])
                .then(async response => {
                    if (response.data.data[endpoint]) {
                        const item = response.data.data[endpoint];
                        if (item.templates && item.templates[0]) {
                            for (let i = 0; i < item.templates.length; i++) {
                                const template = item.templates[i];
                                if (template.type == 'FORM') {
                                    return template;
                                }
                            }
                        }
                    }
                });
        },
        async saveForm(isSubmit) {
            let timestamp = this.$moment().toISOString();
                const measurements = this.workflowjob_response.measurements;

                let response = {
                    isActive: true,
                    taskId: this.task.id,
                    jobId: this.cell.jobId,
                    userId: this.workflowJob.owner ? this.workflowJob.owner.id : this.$eod.getUser().id,
                    statusCode: 'in_progress',
                    isProcessed: false,
                    source: this.$helper.getSystemInfo(),
                    products: [],
                    productTypes: [],
                };

                let activeResponse = this.workflowjob_response;
                if (this.response) {
                    activeResponse = this.response;
                }

                if (activeResponse) {
                    if (activeResponse.timetrackings) {
                        response.timetrackings = activeResponse.timetrackings;
                    }
                    if (activeResponse.comments) {
                        response.comments = [];
                        for (let i = 0; i < activeResponse.comments.length; i++) {
                            const comment = activeResponse.comments[i];
                            if (comment.images) {

                                if (typeof comment.images == 'string') {
                                    comment.images = JSON.parse(comment.images);
                                }

                                for (let j = 0; j < comment.images.length; j++) {
                                    const image = comment.images[j];
                                    if (!image.id) {
                                        let storageObject = await this.$eod.uploadImage({
                                            data: image,
                                            path: this.task.project.id + '/' + this.task.service.id + '/' + this.task.id + '/' + this.cell.jobId + '/images',
                                            storageObject: {
                                                serviceId: this.task.service.id,
                                                projectId: this.task.project.id,
                                                taskId: this.task.id,
                                                taskTypeId: this.task.taskType.id,
                                                companyId: this.task.company.id,
                                            }
                                        }).then(response => {
                                            return response.data.storageObject;
                                        });

                                        comment.images[j] = { id: storageObject.id, key: storageObject.key, name: storageObject.name };
                                    }
                                }

                                comment.images = JSON.stringify(comment.images);
                            }
                            response.comments.push(comment);
                        }

                    }
                }

                if (this.workflowjob_response.id && isNaN(this.workflowjob_response.id)) {
                    response.id = this.workflowjob_response.id;
                }

                let reportedAtTime = timestamp;
                if(activeResponse.reportedAt){
                    reportedAtTime = activeResponse.reportedAt;
                }

                if (isSubmit) {
                    let jobData = await this.workflowJob.getSaveData();
                    jobData.reportedAt = reportedAtTime;
                    await this.updateTask(jobData);

                    let taskData = await this.task.getSaveData();
                    taskData.reportedAt = reportedAtTime
                    await this.updateTask(taskData);

                    response.reportedAt = reportedAtTime;
                }

                if (isSubmit && !this.cell.settings.verify) {
                    response.isProcessed = true;
                }

                let newResponse = await this.$eod.save('Response', response).then(callResponse => {
                    if (callResponse.data.data.createResponse) {
                        return callResponse.data.data.createResponse;
                    } else if (callResponse.data.data.updateResponse) {
                        return callResponse.data.data.updateResponse;
                    }
                });

                const createMeasurements = [];
                const updateMeasurements = [];
                for (let i = 0; i < measurements.length; i++) {
                    const measurement = measurements[i];

                    if (measurement.value != null) {
                        measurement.value = measurement.value.toString();
                    } else if (measurement.isCreate) {
                        continue;
                    }

                    if (measurement.storageObjects) {
                        for (let imageIndex = 0; imageIndex < measurement.storageObjects.length; imageIndex++) {
                            const image = measurement.storageObjects[imageIndex];
                            if (image.id == null) {
                                let storageObject = await this.$eod.uploadImage({
                                    data: image.key,
                                    path: this.task.project.id + '/' + this.task.service.id + '/' + this.task.id + '/' + this.cell.jobId + '/images',
                                    storageObject: {
                                        name: image.name,
                                        serviceId: this.task.service.id,
                                        projectId: this.task.project.id,
                                        taskId: this.task.id,
                                        userId: this.$eod.getUser().id,
                                        taskTypeId: this.task.taskType.id,
                                        companyId: this.task.company.id,
                                        measurementTypeId: measurement.measurementType.id,
                                        productId: measurement.product ? measurement.product.id : null,
                                    }
                                }).then(response => {
                                    return response.data.storageObject;
                                });

                                measurement.storageObjects[imageIndex] = { id: storageObject.id, key: storageObject.key, name: storageObject.name };
                            }
                        }
                    } else {
                        measurement.storageObjects = [];
                    }

                    const newMeasurement = {
                        ...measurement,
                        ...{
                            task: { id: this.task.id },
                            job: { id: this.cell.jobId },
                            owner: { id: this.$eod.getUser().id },
                            response: { id: newResponse.id },
                            project: { id: this.task.project.id },
                            service: { id: this.task.service.id },
                            company: { id: this.task.company.id },
                            taskType: { id: this.task.taskType.id },
                            jobType: { id: this.workflowJob.jobType.id },
                            time: measurement.time ? measurement.time : timestamp,
                            organization: { id: this.$eod.getRealm() },
                            isActive: (isSubmit && !this.cell.settings.verify) ? true : false
                        }
                    };

                    const saveData = (new Measurement(newMeasurement)).getSaveData();

                    if (saveData.isCreate) {
                        delete saveData.isCreate;
                        createMeasurements.push(saveData);
                    } else {
                        updateMeasurements.push(saveData);
                    }
                }

                if (createMeasurements.length > 0) {
                    await this.$eod.createMultiple('Measurement', createMeasurements, {
                        returnfields: ['time', 'value']
                    });
                }

                if (updateMeasurements.length > 0) {
                    await this.$eod.updateMultiple('Measurement', updateMeasurements, {
                        returnfields: ['time', 'value']
                    });
                }
            }
    }
}
</script>