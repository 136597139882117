import Base from "./base";

export default class Article extends Base {
    static searchConfig = [{
        icon: 'mdi-format-text',
        label: 'Naam',
        type: 'property',
        settings: {
            fields: ['name'],
            title: '{name}',
        },
        filter: 'tree_objects_view.name'
    },
    {
        icon: 'mdi-file-sign',
        label: 'Type',
        type: 'module',
        settings: {
            module: 'articleTypes',
            fields: ['name'],
            title: '{name}',
        },

        filter: 'tree_objects_view.type_id'
    },
    {
        icon: 'mdi-alarm',
        label: 'Wijzigingsdatum',
        type: 'date-between',
        filter: 'updatedAt'
    },
    ];
    static exportFields = ['id', 'name', 'description', 'articleType{name}'];
    static moduleName = 'articles';
    static relations = {
        oneToMany: ['articleType', 'unit']
    }
}